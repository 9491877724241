.div{
  width: 36px;
  height: 36px;

  > img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    object-fit: cover;
  }
}